:root {
  --main-color: #CC5500;
  --second-color: #F5F5DC;
  --white-color: white;
  --font-size: 1.8vw;
  --gray: #777;
  --light-gray: #f5f5f5;
  --radius: 7px;
  
  // Font Size 
  --font-size-base: 1rem; /* 16px */
  --font-size-small: 0.875rem; /* 14px */
  --font-size-large: 1.25rem; /* 20px */

  --font-size-h1: 2.25rem; /* 36px */
  --font-size-h2: 1.875rem; /* 30px */
  --font-size-h3: 1.5rem; /* 24px */
  --font-size-h4: 1.25rem; /* 20px */
  --font-size-h5: 1rem; /* 16px */
  --font-size-h6: 0.875rem; /* 14px */
  
}


$grey: #777;
$black: #333;
$background: #f2f2f2;
$raduis: 15px;
$sectionPadd: 30px 0;



h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

h6 {
  font-size: var(--font-size-h6);
}

small, .text-small {
  font-size: var(--font-size-small);
}
