.services {
    .boxes {
        padding: 50px 0;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        overflow: auto;
        // margin: 0 100px;
        margin: auto;
        gap: 10px;
        @media (min-width:767px) {
            gap: 30px;
            .box {
                padding: 60px 80px;
            }
            
        }
        .box{
            border-radius: var(--radius);
            background-color: white !important;
            padding: 30px 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            img {
                width: 30px;
            }
            span {
                text-wrap: nowrap;
            }
        }   
    }
}