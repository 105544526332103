@charset "UTF-8";
:root {
  --main-color: #CC5500;
  --second-color: #F5F5DC;
  --white-color: white;
  --font-size: 1.8vw;
  --gray: #777;
  --light-gray: #f5f5f5;
  --radius: 7px;
  --font-size-base: 1rem; /* 16px */
  --font-size-small: 0.875rem; /* 14px */
  --font-size-large: 1.25rem; /* 20px */
  --font-size-h1: 2.25rem; /* 36px */
  --font-size-h2: 1.875rem; /* 30px */
  --font-size-h3: 1.5rem; /* 24px */
  --font-size-h4: 1.25rem; /* 20px */
  --font-size-h5: 1rem; /* 16px */
  --font-size-h6: 0.875rem; /* 14px */
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

h6 {
  font-size: var(--font-size-h6);
}

small, .text-small {
  font-size: var(--font-size-small);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: auto !important;
}

body {
  font-size: var(--font-size-base);
  font-family: "Tajawal" !important;
  font-display: swap;
  font-weight: bold;
  background-color: #F7F8F9 !important;
}

li {
  list-style: none;
}

a {
  cursor: pointer;
  text-decoration: none !important;
  color: #333 !important;
}

p {
  margin: 0;
}

input:focus {
  box-shadow: 0 2px 15px var(--main-color) !important;
  transition: all 1s !important;
  font-family: "Noto Sans Arabic";
  font-weight: bold;
  letter-spacing: 1px;
}
input:focus::-moz-placeholder {
  -moz-transition: all 1s !important;
  transition: all 1s !important;
  opacity: 0 !important;
}
input:focus::placeholder {
  transition: all 1s !important;
  opacity: 0 !important;
}

::-moz-selection {
  color: var(--second-color);
  background: var(--main-color);
}

::selection {
  color: var(--second-color);
  background: var(--main-color);
}

@font-face {
  font-family: "Tajawal";
  src: url("./../../public/fonts/Tajawal/Tajawal-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Arabic";
  src: url("./../../public/fonts/Noto_Sans_Arabic/static/NotoSansArabic_ExtraCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Arabic";
  src: url("./../../public/fonts/Noto_Sans_Arabic/static/NotoSansArabic_SemiCondensed-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Extrava";
  src: url("./../../public/fonts/Extrava/Extrava.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-track {
  width: 8px !important;
  background-color: var(--second-color) !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color) !important;
}

@font-face {
  font-family: "Tajawal";
  src: url("./../../public/fonts/Tajawal/Tajawal-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Tajawal";
  src: url("./../../public/fonts/Tajawal/Tajawal-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Tajawal";
  src: url("./../../public/fonts/Tajawal/Tajawal-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Tajawal";
  src: url("./../../public/fonts/Tajawal/Tajawal-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Tajawal";
  src: url("./../../public/fonts/Tajawal/Tajawal-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Tajawal";
  src: url("./../../public/fonts/Tajawal/Tajawal-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
.button-href {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5%;
  right: 50%;
  width: 100%;
  transform: translateX(50%);
  position: absolute;
}
.button-href button {
  border: none;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 2px 15px 5px #fff;
  padding: 15px 30px;
}
.button-href button span {
  color: var(--main-color);
  font-size: 27px;
  font-weight: bold;
}
.button-href button img {
  width: 35px;
  height: 35px;
  transform: rotate(45deg);
}

.extrava-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  text-align: center;
  background: var(--main-color);
  padding: 7px 15px;
  width: 250px;
  height: 60px;
  border: 0;
  border-radius: 22px;
  color: white;
  font-weight: bold;
  box-shadow: 0px 2px 15px #ccc;
}
.extrava-button .effect-img {
  width: 30px;
  position: absolute;
  top: -14px;
  left: -12px;
  z-index: -1;
  transform: rotate(275deg);
}
.extrava-button .icon-img {
  width: 40px;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 30px 0;
}
.heading .heading-desc {
  font-weight: 900;
  color: var(--gray);
  font-size: 20px;
  margin: 0;
}
.heading .heading-title {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--main-color);
  font-weight: bold;
  margin-bottom: 60px;
  font-size: 45px;
  font-weight: 900;
  margin: 0;
}
@media (max-width: 500px) {
  .heading .heading-title {
    font-size: 35px;
  }
}

.category {
  padding: 30px 0;
}
.category .boxes {
  display: flex;
  align-items: center;
  flex-wrap: nowrap !important;
  flex-direction: row-reverse;
  overflow-x: auto; /* التمرير الأفقي */
  padding-bottom: 10px;
  /* إضافات جديدة */
  position: relative;
}
.category .boxes .box {
  width: 180px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.category .boxes .box img {
  width: 140px;
  height: 140px;
}
.category .boxes .box h3 {
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 23px !important;
}

header:has(+ .landing),
header:has(+ .remove-shadow) {
  box-shadow: none !important;
}

.landing {
  background-color: var(--main-color);
  background-image: linear-gradient(45deg, var(--main-color), rgba(255, 255, 255, 0.4), var(--main-color));
  animation: color 15s ease-in-out infinite;
  background-size: 300% 300%;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 84vh;
  position: relative;
}
.landing::before {
  content: "";
  background-image: url("../../public/imgs/man-scooter.webp");
  background-position: center;
  width: 130px;
  height: 130px;
  top: 20px;
  left: 0px;
  position: absolute;
  background-size: cover;
}
.landing::after {
  content: "";
  background-image: url("../../public/imgs/icons/arrow_straight1.png");
  background-position: center;
  width: 140px;
  height: 140px;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(50deg);
  position: absolute;
  background-size: cover;
}
@media (min-width: 450px) {
  .landing::after {
    display: none;
  }
}
.landing .banner {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  font-weight: bold;
  gap: 30px;
  padding: 40px 30px;
}
.landing .banner h1.logo-desc {
  padding: 5px !important;
}
.landing .banner .welcome, .landing .banner .logo-name, .landing .banner .logo-desc {
  font-size: 40px;
  color: white;
  width: 100%;
  text-align: center;
}
.landing .banner .welcome {
  font-size: 3rem;
  text-align: right;
}
.landing .banner .logo-name {
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 6rem;
  text-shadow: 0 1px 15px #ccc;
  font-family: "Extrava" !important;
}
.landing .new-banner {
  background-color: var(--main-color);
  height: calc(100vh - 138px);
}

.best-seller {
  padding: 30px 0;
  text-align: center;
}
.best-seller .container h2 {
  color: var(--main-color);
  font-weight: bold;
}
.best-seller .container .row .hide-box .box {
  overflow: hidden;
  box-shadow: 0 2px 15px #ccc;
  border-radius: 10px;
}
.best-seller .container .row .hide-box .box .image {
  width: 100%;
}
.best-seller .container .row .hide-box .box .image img {
  height: 330px;
  width: 330px;
  -o-object-fit: contain;
     object-fit: contain;
}
.best-seller .container .row .hide-box .box .text {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.best-seller .container .row .hide-box .box .text .desc {
  font-size: 16px;
  color: #777;
}
.best-seller .container .row .hide-box .box .text .title {
  font-size: 20px;
  font-weight: 900;
}

.sectionPadd {
  padding: 30px 0;
}

@keyframes color {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.services .boxes {
  padding: 50px 0;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  overflow: auto;
  margin: auto;
  gap: 10px;
}
@media (min-width: 767px) {
  .services .boxes {
    gap: 30px;
  }
  .services .boxes .box {
    padding: 60px 80px;
  }
}
.services .boxes .box {
  border-radius: var(--radius);
  background-color: white !important;
  padding: 30px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.services .boxes .box img {
  width: 30px;
}
.services .boxes .box span {
  text-wrap: nowrap;
}

.policy {
  text-align: end;
  padding: 10px 0;
  margin-top: 30px 0;
  position: relative;
}
.policy .logo-img {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-image: url("../../public/imgs/extrava-no-word.svg");
  background-repeat: repeat-y;
  background-size: contain;
  opacity: 0.045;
}
.policy .content {
  position: relative;
}
.policy p {
  font-size: var(--font-size-meduim);
  color: #555;
  line-height: 1.6;
}
.about {
  border: 10px solid var(--main-color);
  border-radius: 10px;
  padding-top: 60px;
  margin: 30px 10px;
}
.about .image {
  position: absolute;
  top: 29px;
  left: 0;
  background-color: var(--main-color);
  width: 82px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
}
.about img {
  width: 75px;
}
.about h1 {
  font-family: "Extrava" !important;
}
.about p {
  font-size: 18px;
  color: #666;
}

.contact-us {
  padding: 30px 0;
  position: relative;
}
.contact-us::before {
  content: "";
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: left bottom;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: auto;
  bottom: -4px;
}
@media (max-width: 768px) {
  .contact-us::before {
    display: none;
  }
}
.contact-us .container .row {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 500px) {
  .contact-us .container .row {
    flex-direction: column-reverse;
  }
}
.contact-us .container .row div form {
  border-radius: var(--radius);
}
.contact-us .container .row div form span {
  font-weight: 900;
  font-size: var(--font-size-base);
  display: block;
  width: 100%;
}
.contact-us .container .row div form input,
.contact-us .container .row div form textarea {
  direction: rtl;
  box-shadow: 0 2px 10px #ccc;
  text-align: start;
}
.contact-us .container .row div form input:focus,
.contact-us .container .row div form textarea:focus {
  box-shadow: 0 0 0 0 #333 !important;
  border-color: #333 !important;
}
.contact-us .container .row div form input::-moz-placeholder, .contact-us .container .row div form textarea::-moz-placeholder {
  font-weight: 500;
  color: #aaa;
}
.contact-us .container .row div form input::placeholder,
.contact-us .container .row div form textarea::placeholder {
  font-weight: 500;
  color: #aaa;
}
.contact-us .container .explain .text h4 {
  font-weight: 900;
}
.contact-us .container .explain .text p {
  color: var(--gray);
  line-height: 1.6;
}

.details .image-part .image {
  padding: 0 20px;
}
.details .image-part .image img {
  border: 5px solid #CC5500;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  min-width: 335px;
  min-height: 335px;
}

.image.correct {
  position: relative;
  width: 25px;
  height: 25px;
}
.image.correct::before {
  content: "";
  position: absolute;
  background-color: rgb(91, 180, 91);
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -1;
  animation: fadeAndGrow 5s infinite;
}
.details .details-part .evaluation img {
  width: 25px;
  height: 25px;
}

@keyframes fadeAndGrow {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.adult {
  margin-top: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-wrap: wrap;
}
.adult span, .adult h2 {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #CC5500;
  color: white;
  font-weight: bold;
  padding: 7px 10px;
  font-size: 11px;
  border-radius: 4px;
  height: 28.5px;
  min-width: 80px;
  text-align: center;
  margin: 0 !important;
}

.email-article {
  padding: 30px 0;
  background-color: white;
}
.email-article .container form input, .email-article .container .form input {
  border: 20px solid var(--main-color);
  border-radius: 30px;
  padding: 13px 10px;
  text-align: center;
}
.sub-footer {
  height: 80px;
}
.sub-footer .images {
  background-color: white;
}
.sub-footer .images div, .sub-footer .images a {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 45px #eee;
  display: inline-block;
}
.sub-footer .images img {
  height: 25px;
  min-width: 25px;
}/*# sourceMappingURL=main.css.map */