.email-article {
    padding: $sectionPadd;
    background-color: white;
    .container {

        .form {      
            input {
                border: 20px solid var(--main-color);
                border-radius: 30px;
                padding: 13px 10px;
                    text-align: center ;
            }
            button {
                
            }
        }
    }

}