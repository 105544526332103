// footer {
//     background-color: #913c00;
//     color: white !important;
//     ul,li,a {
//         color: white !important;

//     }

// }

.sub-footer {
    height: 80px;
    
    .images {
        background-color: white ;
        div,a  {
            padding: 5px;
            border-radius: 5px;
            box-shadow: 0 2px 45px #eee;
            display: inline-block;
        }
        img {
               height: 25px;
               min-width: 25px;
                }
    }
}