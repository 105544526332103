.button-href {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 5%;
    right: 50%;
    width: 100%;
    transform: translateX(50%);
    // @media(max-width:767px) {
    //     bottom: 15px;
    //     right: 30px;
    // }
    position: absolute;
    // background: #198754;
    button {
        border: none;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content:center;
        gap: 10px;  
        box-shadow: 0 2px 15px 5px #fff;
        padding: 15px 30px;
        // @include respond-to-xsmall() {
        //     gap: 5px;
        // padding: 6px 10px;
        // }
        // @include respond-to-small() {
        //     gap: 5px;
        // padding: 7px 12px;
        // }
        // @include respond-to-medium() {
        //     gap: 10px;
        // padding: 10px 20px;
        // }
       
        span {
            color: var(--main-color);
            font-size: 27px;
            font-weight: bold;
            // @include respond-to-xsmall() {
            //     font-size: 12px;
            // }
            // @include respond-to-small() {
            //     font-size: 15px;
            // }
            // @include respond-to-medium() {
            //     font-size: 25px;
            // }
            
        }
        img {
            width: 35px;
            height: 35px;
        //     @media(max-width:767px) {
        //         width: 20px;
        //     }
            
        // @include respond-to-medium() {
        //     width: 40px;
        // }
            transform: rotate(45deg)
        }
    }
}

 
    
.extrava-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    .effect-img {
        width: 30px;
        position: absolute;
        top: -14px;
        left: -12px;
        z-index: -1;
        transform: rotate(275deg);
        }
        .icon-img {
            width: 40px;
        } 
        text-align: center;
        background: var(--main-color);
        padding: 7px 15px;
        width: 250px;
        height: 60px;
        border: 0;
        border-radius: 22px;
        color: white;
        font-weight: bold;
        box-shadow: 0px 2px 15px #ccc;
    }
