// .category {
//     padding: $sectionPadd;
  
//     .boxes {
//       display: flex;
//       justify-content: flex-end; /* تغيير التوجه الأفقي */
//       align-items: center; /* تغيير التوجه العمودي */
//       flex-wrap: nowrap !important;
//     //   gap: x;
//       overflow-x: auto; /* التمرير الأفقي */
//       padding-bottom: 10px;
  
//       .box {
//         width: 250px;
//         flex-shrink: 0;
//         display: flex;
//         flex-direction: column;
//         justify-content: end;
//         align-items: center; /* التوسيط */
//         img {
//         //   max-width: 100%;
//         height: 100%;
//         width: 100%;
//         }
//         h3 {
//           margin-top: 15px;
//           text-align: center;
//           font-weight: bold;
//         }
//       }
//     }
//   }
  
.category {
    padding: $sectionPadd;
  
    .boxes {
      display: flex;
      align-items: center;
      flex-wrap: nowrap !important;
      flex-direction: row-reverse;
      overflow-x: auto; /* التمرير الأفقي */
      padding-bottom: 10px;
      
      /* إضافات جديدة */
      position: relative;
      // justify-content: flex-start; /* البداية من اليسار، العناصر الداخلية ستتحرك نحو اليمين باستخدام margin-left */
  
      .box {
        width: 180px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // margin-left: auto; /* يدفع كل العناصر إلى النهاية */
        img {
          width: 140px;
          height: 140px;
        }
        h3 {
          margin-top: 15px;
          text-align: center;
          font-weight: bold;
          font-size: 23px !important;
        }
      }
    }
  }
  