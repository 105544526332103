.about {
    border: 10px solid var(--main-color);
    border-radius: 10px;
    padding-top: 60px ;
    margin:  30px 10px ;
    .image{
        position: absolute;
    top: 29px;
    left: 0;
    background-color: var(--main-color);
    width: 82px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 10px 10px 0;
    }
    img {
        width: 75px;
    }

    h1  {
        font-family: 'Extrava' !important;
        
    }
    div {

        h2 {
            // font-family: 'Dubi' !important;
           }
    }
        p {
            font-size: 18px;
        color: #666;
    }
    
}