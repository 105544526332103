.policy {
    text-align: end;
    padding: 10px 0;
    margin-top: 30px 0;
    position: relative;
    .logo-img {
        position: absolute;
        top: 0;
        right: 0;
        // : 50%;
        // transform: translateX(-50%);
        height: 100%;
        width: 100%;
        background-image: url("../../public/imgs/extrava-no-word.svg");
        background-repeat: repeat-y;
        background-size: contain;
         opacity: 0.045;

    }
    .content {
        position: relative;
    }
    h1 {
    }
    p {
        font-size: var(--font-size-meduim);
        color: #555;
        line-height: 1.6;
    }
    h3 {
        
    }
}