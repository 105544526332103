.contact-us {
    padding: 30px 0;
    position: relative;
    &::before {
      content:"";
      // background-image: url("../../public/imgs/employee.png");
      background-repeat: no-repeat;
      background-size: 300px;
      background-position: left bottom;
      z-index: -1  ;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: auto;
      bottom:-4px;
      
      @media(max-width:768px) {
        display: none;
      }

      
    }
    .container {
      .row {
        @media (max-width: 500px) {
          flex-direction: column-reverse;
        }
        display: flex;
        flex-direction: row-reverse;
        div {
          form {
            // background-color: var(--main-color);
            // border: 2px solid #333;
            border-radius: var(--radius);
            // box-shadow: 10px 10px 0px #333;
            span {
              font-weight: 900;
              font-size:var(--font-size-base);
              // color: white;
              display: block;
              // text-align: right;
              width: 100%;
            }
            input,
            textarea {
              direction: rtl;
              // border: 2px solid #333;
              box-shadow:0 2px 10px #ccc;
              text-align:start;
              &:focus {
                box-shadow: 0 0 0 0 #333 !important;
                border-color: #333 !important;
              }
              &::placeholder {
                font-weight: 500;
                color: #aaa;
              }
            }
          }
        }
      }
      .explain {
        img {
        }
        .text {
          h4 {
            font-weight: 900;
          }
          p {
            color: var(--gray);
            line-height: 1.6;
          }
        }
      }
    }
  }
  