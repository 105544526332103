.details {

    .image-part {
        .image {
            padding:0  20px;
            img {
                border: 5px solid #CC5500;
                border-radius: 10px;
                max-width: 100%;
                height: auto;
                min-width: 335px;
                min-height: 335px;
            }
        }
    }   
}

.image.correct {
    position: relative;
    width: 25px;
    height: 25px;
    &::before {
        content:"";
        position: absolute;
        background-color: rgb(91, 180, 91);
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: -1;
        animation: fadeAndGrow 5s infinite;

    }
    .img {
        
    }
}

.details {
    .details-part {
        .product {

        }
        .title {
        }
            .evaluation {
                img {
                    // Stars
                    width: 25px;
                    height: 25px;
                }
            }
    }
}
@keyframes fadeAndGrow {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .adult {
    margin-top: 15px;
    margin-right: 10px;
    display: flex;
    justify-content: end;
    gap: 10px;
    flex-wrap: wrap;

    span,h2 {
        width: fit-content;
        background-color: #CC5500;
        color: white;
        font-weight: bold;
        padding: 7px 10px;
        font-size: 11px;
        border-radius: 4px;
        height: 28.5px;
        min-width: 80px;
        text-align: center;
        margin: 0 !important;
    }
  }
