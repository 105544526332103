.heading {
   display: flex;
   flex-direction: column;
   align-items: end ;
    // margin-bottom: 50px;
    padding: $sectionPadd;
  
   
    .heading-desc {
      


      font-weight: 900;
      color: var(--gray);
      font-size: 20px;
      margin: 0;
    }
    .heading-title {
      width: fit-content;
      color: var(--main-color);
      font-weight: bold;
      margin-bottom: 60px;
      font-size: 45px;
      @media (max-width: 500px) {
        font-size: 35px;
      }
      font-weight: 900;
      margin: 0;
    }
  }
  