* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: auto !important;
}
body {
  font-size: var(--font-size-base);
  font-family: 'Tajawal' !important;
  // font-family: 'Noto Sans Arabic'!important;
  font-display: swap;
  font-weight: bold;
 background-color: #F7F8F9 !important; 
 
}

li {
  list-style: none;
}
a {
  cursor: pointer;
  text-decoration: none !important  ;
  color: #333 !important;
}
p {
  margin: 0;
}
input {
  &:focus {
    box-shadow: 0 2px 15px var(--main-color) !important;
    transition: all 1s !important;
    font-family: "Noto Sans Arabic";
    font-weight: bold;
    letter-spacing: 1px;
  }
  &:focus {
    &::placeholder {
      transition: all 1s !important;
      opacity: 0 !important; // تخفي النص
    }
  }
}

::selection {
  color: var(--second-color);
  background: var(--main-color);
}

@font-face {
  font-family: 'Tajawal';
  src: url('./../../public/fonts/Tajawal/Tajawal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('./../../public/fonts/Noto_Sans_Arabic/static/NotoSansArabic_ExtraCondensed-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Arabic';
  src: url('./../../public/fonts/Noto_Sans_Arabic/static/NotoSansArabic_SemiCondensed-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}



// @font-face {
//   font-family: 'Noto Sans Arabic';
//   src: url('/fonts/NotoSansArabic-Bold.woff2') format('woff2'),
//        url('/fonts/NotoSansArabic-Bold.woff') format('woff'),
//        url('/fonts/NotoSansArabic-Bold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }




@font-face {
  font-family: 'Extrava';
  src: url('./../../public/fonts/Extrava/Extrava.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-track {
  width: 8px !important;
  background-color: var(--second-color) !important;
}
::-webkit-scrollbar-thumb {
  // width: 10 !important;
  background-color: var(--main-color) !important;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./../../public/fonts/Tajawal/Tajawal-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./../../public/fonts/Tajawal/Tajawal-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./../../public/fonts/Tajawal/Tajawal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./../../public/fonts/Tajawal/Tajawal-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./../../public/fonts/Tajawal/Tajawal-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./../../public/fonts/Tajawal/Tajawal-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}