header:has(+ .landing),
header:has(+ .remove-shadow) {
  box-shadow: none !important;
}





// .landingf  {
//   margin: 20px 0;
//   border-radius: var(--radius);
//   background-image: url("../../public/imgs/landing-img.webp");
//   height: calc(100vh - 149px);
//   width: 100%;
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center top;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   align-items: center;
//   position: relative;
//   padding: 50px;
//   h1 {
//     font-size: 50px;
//   }
//   h2 {
//     font-size: 35px;
//   }
//   h1,
//   h2 {
//     font-weight: bold;
//     color: white;
//     position: relative;
//     // z-index: 1;
//   }
// }

.landing {
  background-color: var(--main-color);
  // background-image: url("../../public/imgs/light.svg");
  background-image: linear-gradient(45deg, var(--main-color), rgba(255, 255, 255, 0.4),var(--main-color));
  animation: color 15s ease-in-out infinite; 
background-size: 300% 300%;
  background-position: top center ;
  background-repeat: no-repeat;
  width: 100vw;
  height: 84vh;
  position: relative;
  &::before {
    content:"";
    background-image: url("../../public/imgs/man-scooter.webp");
    background-position: center;
    width: 130px;
    height: 130px;
    top: 20px;
    left: 0px;
    position: absolute;
    background-size: cover;
 }
  &::after {
    content:"";
    background-image: url("../../public/imgs/icons/arrow_straight1.png");
    background-position: center;
    width: 140px;
    height: 140px;
    bottom: 20%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(50deg);
    position: absolute;
    background-size: cover;

    @media(min-width: 450px) {

      display: none;
    }

 }
.banner {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  font-weight: bold;
  gap: 30px;
  padding: 40px 30px;
  h1.logo-desc {
    
    padding: 5px  !important
  }
  .welcome,.logo-name,.logo-desc {
    // position: absolute;
    font-size: 40px;
    color: white;
    width: 100%;
    text-align: center;
  }
  .welcome {
    font-size: 3rem;
    text-align: right;
  }
  .logo-name {
    width: 100%;
    justify-content: center;
    text-align: center;
    font-size: 6rem;
    text-shadow: 0 1px 15px #ccc;
    font-family: "Extrava" !important ;
  }
  
  // h1 {
  //   padding: 5px  !important;
  //   background: white;
  //   color: black;
  //   padding: 6px;
  //   border-radius: 8px;
  //   box-shadow: 0 2px 15px #fff;
  //   color: var(--main-color);
  //   font-weight: bold;
  //   margin: 0px 0;
  // }
//   .welcome {
//     @include landing-respond(xsmall,18px,22px,13px);
//     @include landing-respond(small,20px,30px,17px);
//     @include landing-respond(medium,30px,45px,22px);
//     @include landing-respond(large,42px,60px,33px);
//     @include landing-respond(xlarge,54px,70px,35px);
//   }
//   .logo-name {
//     @include landing-respond(xsmall,47px,45px,20px);
//     @include landing-respond(small,64px,70px,30px);
//     @include landing-respond(medium,89px,85px,22px);
//     @include landing-respond(large,99px,125px,60px);
//     @include landing-respond(xlarge,125px,140px,70px);
    
//   }
//   .logo-desc {
//     @include landing-respond(xsmall,14px,110px,40px);
//     @include landing-respond(small, 20px,160px,60px);
//     @include landing-respond(medium,28px,214px,80px);
//     @include landing-respond(large, 40px,275px,108px);
//     @include landing-respond(xlarge,49px,329px,130px);
//   }
}


.new-banner {
  // background-image: url("../../public/imgs/orange.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: var(--main-color);
  height: calc(100vh - 138px);
}

}


// Best Seller

.best-seller {
  padding: $sectionPadd;
  text-align: center;
  .container {
    h2 {
      color: var(--main-color);
      font-weight: bold;
      // margin-bottom: 60px;
    }
    .row {
      .hide-box {
        .box {
          overflow: hidden;
          box-shadow: 0 2px 15px #ccc;
          border-radius: 10px;
          .image {
            width: 100%;
            img {
              height: 330px;
              width: 330px;
              object-fit: contain;
            }
          }
          .text {
            padding: 15px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            .desc {
              font-size: 16px;
              color: #777;
            }
            .title {
              font-size: 20px;
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}
.sectionPadd {

  padding: $sectionPadd;
}


@keyframes color {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }

}
  
  